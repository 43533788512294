
import { defineComponent } from "vue";
import Footer from "@/components/Footer.vue"; // @ is an alias to /src

export default defineComponent({
  name: "App",
  components: {
    Footer,
  },
});
