
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
});
