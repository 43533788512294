
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  data() {
    return {
      formVisible: false,
      formSent: false,
      title: "",
      email: "",
      message: "",
    };
  },
  methods: {
    async sendForm() {
      if (!this.$data.title || !this.$data.email || !this.$data.message) {
        window.alert("All fields are required");
      } else if (!this.$data.email.includes("@")) {
        window.alert("Please check your email");
      } else {
        const resp = await fetch("/api/contact", {
          method: "POST",
          body: JSON.stringify({
            title: this.$data.title,
            email: this.$data.email,
            content: this.$data.message,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (resp.ok) {
          this.$data.title = "";
          this.$data.email = "";
          this.$data.message = "";
          this.$data.formVisible = false;
          this.$data.formSent = true;
        } else {
          window.alert(
            "There was some problem sending your message, please try again later"
          );
        }
      }
    },
  },
});
